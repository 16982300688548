import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PUBSUB_TOPICS, usePageTitle, useSubscriber } from '../../hooks';
import { EMAIL_ACTIONS } from '../../store/actions/reducer-actions/email';
import { Status } from '../../store/models/europrisme';
import { User } from '../../store/reducers/users';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Input from '../elements/Input';
import PageTitle from '../elements/PageTitle';
import ScrollToTop from '../utils/ScrollToTop';
import Paths from './paths';

const ContactSidebar = styled.aside`
  grid-column: 1 / span 3;
  margin: 50px 0;

  font-size: 14px;
  line-height: 21px;
  color: ${Colors.lightGrey};

  @media all and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
    margin: 24px 0;
  }
`;

const ContactContent = styled.div`
  grid-column: 5 / span 7;
  margin: 50px 0;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
    margin-top: 0;
    margin-bottom: 32px;
  }

  h1 {
    margin-top: 0;

    @media all and (max-width: ${Sizing.mobileWidth}) {
      margin-bottom: 16px;
    }
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;

  transition: opacity 0.2s;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const ContactFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  &:not(:first-child) {
    margin-top: 20px;

    @media all and (max-width: ${Sizing.mobileWidth}) {
      margin-top: 16px;
    }
  }

  @media all and (max-width: ${Sizing.mobileWidth}) {
    flex-direction: column;
  }

  label {
    flex-grow: 1;

    span {
      color: ${Colors.lightGrey};

      @media all and (max-width: ${Sizing.mobileWidth}) {
        margin-bottom: 4px;
      }
    }

    &:nth-child(2) {
      margin-left: 40px;

      @media all and (max-width: ${Sizing.mobileWidth}) {
        margin-left: 0;
      }
    }

    @media all and (max-width: ${Sizing.mobileWidth}) {
      & + label {
        margin-top: 16px;
      }
    }
  }
`;

const ContactFormInput = props => {
  const { children } = props;

  return (
    <Input
      label={children}
      background={Colors.accent}
      color={Colors.white}
      square
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

ContactFormInput.propTypes = {
  children: PropTypes.node.isRequired,
};

const Contact = ({ status, sendEmail, currentUser }) => {
  usePageTitle('Contact');

  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.name.first);
      setLastName(currentUser.name.last);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const handleSubmit = event => {
    event.preventDefault();

    sendEmail({
      sFirstName: firstName,
      sLastName: lastName,
      sRequirerEmail: email,
      sPhoneNumber: phone,
      sEmailBodyText: message,
    });

    return false;
  };

  useSubscriber(PUBSUB_TOPICS.CONTACT_MESSAGE_SENT_SUCCESS, () => {
    // TODO: Clear the form
    history.push(Paths.Home());
  });

  return (
    <>
      <ScrollToTop />
      <ContactSidebar>
        {appConfig.mapSegments('content.pages.contact.textContent')}
      </ContactSidebar>
      <ContactContent>
        <PageTitle>Envoyez-nous un message</PageTitle>
        <ContactForm
          onSubmit={handleSubmit}
          disabled={status === Status.LOADING}
        >
          <ContactFormRow>
            <ContactFormInput
              type="text"
              name="firstname"
              value={firstName}
              onChange={setFirstName}
              required
            >
              Prénom
            </ContactFormInput>

            <ContactFormInput
              type="text"
              name="lastname"
              value={lastName}
              onChange={setLastName}
              required
            >
              Nom
            </ContactFormInput>
          </ContactFormRow>

          <ContactFormRow>
            <ContactFormInput
              type="email"
              name="email"
              value={email}
              onChange={setEmail}
              required
            >
              Adresse mail
            </ContactFormInput>

            <ContactFormInput type="tel" name="phone" onChange={setPhone}>
              Téléphone
            </ContactFormInput>
          </ContactFormRow>

          <ContactFormRow>
            <ContactFormInput
              type="textarea"
              name="message"
              onChange={setMessage}
              textarea
              required
            >
              Message
            </ContactFormInput>
          </ContactFormRow>

          <ContactFormRow>
            <Checkbox
              label={appConfig.get(
                'content.pages.contact.consentCheckboxLabel',
              )}
              required
            />
          </ContactFormRow>

          <ContactFormRow>
            <Button
              type="submit"
              background={Colors.accent}
              color={Colors.white}
              style={{
                textTransform: 'uppercase',
                fontSize: '10px',
                paddingTop: '14px',
                paddingBottom: '14px',
              }}
            >
              Envoyer
            </Button>
          </ContactFormRow>
        </ContactForm>
      </ContactContent>
    </>
  );
};

Contact.propTypes = {
  status: PropTypes.oneOf(Object.values(Status)).isRequired,
  sendEmail: PropTypes.func.isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any),
};

Contact.defaultProps = {
  currentUser: new User(),
};

const ConnectedContact = connect(
  state => ({ ...state.email, ...state.users }),
  { ...EMAIL_ACTIONS },
)(Contact);

export default {
  path: Paths.Contact(),
  exact: true,
  name: 'Contact',
  component: ConnectedContact,
};
