import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import MattressIcon from '../../../assets/img/MattressIcon';
import { usePageTitle } from '../../hooks';
import { AISLES_ACTIONS, FAMILIES_ACTIONS } from '../../store/actions';
import { Colors } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { Configurators } from '../../utils/misc';
import { connect } from '../../utils/redux';
import BlockPlaceholder from '../elements/BlockPlaceholder';
import ExternalConfigurator from '../elements/ExternalConfigurator';
import ResourceImage from '../elements/ResourceImage';
import { SidebarEntryPanelPseudoLink } from '../elements/SidebarEntry';
import ShopLayout from '../layouts/ShopLayout';
import Paths from './paths';

const SingleFamilyWrapper = styled.div`
  position: relative;
`;

const SingleFamilyNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 15px 20px;

  color: ${Colors.black};
  background-color: ${Colors.pureWhite};
  text-decoration: none;

  .resource-wrapper {
    position: relative;
    display: flex;
    aspect-ratio: 300/230;
    margin: 15px 0;

    img {
      position: absolute;
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }
`;

const SingleFamilyConfiguratorPseudoLink = styled(SidebarEntryPanelPseudoLink)`
  position: absolute;
  top: -6px;
  right: -6px;
  flex-direction: row;
  gap: 10px;
  padding: 2px 6px;

  z-index: 10;

  span {
    font-size: 12px;
  }
`;

const SingleAisle = ({
  match,

  aislesState,
  familiesState,
  productsState,

  fetchSingleAisle,
  fetchFamiliesPerAisle,
}) => {
  const { aisleID } = match.params;
  const aisle = (aislesState.aisles || {})[aisleID];

  const [familyIDs, setFamilyIDs] = useState([]);

  useEffect(() => {
    setFamilyIDs([]);
  }, [aisleID]);

  usePageTitle((aisle || {}).sLibelle);

  const allFamilies = (familiesState || {}).families || {};

  const aisleFamilies = Object.values(allFamilies).filter(
    fam => fam.sRayon === aisleID,
  );

  useEffect(() => {
    if (aisleFamilies.length && familyIDs.length === 0) {
      const ids = aisleFamilies
        .map(fam => fam.sCode)
        .reduce((acc, fam) => {
          if (fam.indexOf(',') !== -1) {
            return [...acc, ...fam.split(',')];
          }

          return [...acc, fam];
        }, []);

      setFamilyIDs(ids);
    }
  }, [aisleID, aisleFamilies]);

  useEffect(() => {
    if (!aisle) fetchSingleAisle({ sRayonCode: aisleID });
    if (aisleFamilies.length === 0) fetchFamiliesPerAisle({ sRayon: aisleID });
  }, [aisleID]);

  return (
    <ShopLayout
      aisle={aisle}
      family={null}
      products={[]}
      totalProducts={0}
      productsState={productsState}
      totalPages={0}
      perPage={15}
      currentPage={1}
    >
      {aisleFamilies &&
        aisleFamilies.map(family => (
          <SingleFamilyWrapper key={[family.sLibelle, family.sCode].join('-')}>
            <SingleFamilyNavLink
              to={Paths.SingleFamily({ aisleID, familyID: family.sCode })}
            >
              <div className="resource-wrapper">
                <ResourceImage
                  type="Famille"
                  code={family.sCode}
                  loader={<BlockPlaceholder height="100%" />}
                />
              </div>

              <div className="resource-name">{family.sLibelle}</div>
            </SingleFamilyNavLink>

            {(
              appConfig.get('settings.connections.mattresses.families', []) ||
              []
            ).includes(family.sCode) && (
              <ExternalConfigurator
                src={Configurators.PRESSURE_ULCER_PREVENTION}
                size="compact"
                scrolling="no"
              >
                <SingleFamilyConfiguratorPseudoLink>
                  <MattressIcon size={24} color="currentColor" />
                  <span>Configurateur</span>
                </SingleFamilyConfiguratorPseudoLink>
              </ExternalConfigurator>
            )}
          </SingleFamilyWrapper>
        ))}
    </ShopLayout>
  );
};

SingleAisle.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,

  aislesState: PropTypes.objectOf(PropTypes.any).isRequired,
  familiesState: PropTypes.objectOf(PropTypes.any).isRequired,
  productsState: PropTypes.objectOf(PropTypes.any).isRequired,

  fetchSingleAisle: PropTypes.func.isRequired,
  fetchFamiliesPerAisle: PropTypes.func.isRequired,
};

export const ConnectedSingleAisle = connect(
  state => ({
    aislesState: state.aisles,
    familiesState: state.families,
    productsState: state.products,
  }),
  { ...AISLES_ACTIONS, ...FAMILIES_ACTIONS },
)(SingleAisle);

export default {
  path: Paths.SingleAisle(),
  name: 'Rayon',
  component: ConnectedSingleAisle,
  exactBreadcrumb: true,
  mapStateToProps: state => ({ ...state.aisles }),
  getFetchParams: ({ match }) => ({ sRayonCode: match.params.aisleID }),
  mapDispatchToProps: { fetch: () => AISLES_ACTIONS.fetchSingleAisle },
  getDataFromState: ({ aisles, path, match }) => {
    const { params, url } = match;
    const { aisleID } = params;

    const aisle = (aisles || {})[aisleID];

    if (aisle) return { name: aisle.sLibelle, path: url };

    return { name: 'Rayon', path };
  },
};
