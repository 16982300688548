import PropTypes from 'prop-types';
import React from 'react';

const PlusIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 50 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.057 32.373v8.072H7.487v-8.072h19.57zm22.547-11.361v8.071H30.752v-8.071h18.852zM27.416 0v21.011h-8.067V0h8.067z"
        fill="#EA7381"
      />
      <path
        d="M27.057 40.444V59.34h-8.072V40.444h8.072zm.359-19.432v8.071H0v-8.071h27.416zM38.824 9.376v11.635h-8.072V9.376h8.072z"
        fill="#DC4751"
      />
    </g>
  </svg>
);

PlusIcon.propTypes = {
  size: PropTypes.number,
};

PlusIcon.defaultProps = {
  size: 15,
};

export default PlusIcon;
