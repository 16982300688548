import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import sanitize from 'sanitize-html';
import styled, { css } from 'styled-components';

import ArrowLeftIcon from '../../../assets/img/ArrowLeftIcon';
import CheckIcon from '../../../assets/img/CheckIcon';
import MattressIcon from '../../../assets/img/MattressIcon';
import { usePageTitle } from '../../hooks';
import { PRODUCT_ACTIONS } from '../../store/actions';
import { CART_ACTIONS } from '../../store/actions/reducer-actions/cart';
import { Status } from '../../store/models/europrisme';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import DateFormat, { Formats } from '../../utils/date';
import { Configurators } from '../../utils/misc';
import { connect } from '../../utils/redux';
import BlockPlaceholder from '../elements/BlockPlaceholder';
import Button from '../elements/Button';
import Details from '../elements/Details';
import DocumentViewer from '../elements/DocumentViewer';
import ExternalConfigurator from '../elements/ExternalConfigurator';
import NumberInput from '../elements/NumberInput';
import ProductPrice, { PriceMode } from '../elements/price/ProductPrice';
import ProductDelay from '../elements/ProductDelay';
import ResourceImage from '../elements/ResourceImage';
import ShopSidebar from '../elements/ShopSidebar';
import Spinner from '../elements/Spinner';
import ErrorBoundary from '../utils/ErrorBoundary';
import ScrollToTop from '../utils/ScrollToTop';
import Paths from './paths';

const ProductContent = styled.section`
  display: block;
  padding-top: 40px;
  grid-column-start: 4;
  grid-column-end: span 9;
`;

const ProductRow = styled.div`
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  > .row-1 {
    grid-row-end: span 1;
  }

  > .row-2 {
    grid-row-end: span 2;
  }

  > .col-4 {
    grid-column-end: span 4;
  }

  .document-viewers {
    &.loading {
      display: flex;
      justify-content: center;
    }

    button + button {
      margin-top: 12px;
    }
  }
`;

const ProductImageWrapper = styled.div`
  position: relative;
  display: block;
  grid-column-start: 1;
  grid-column-end: span 4;

  background-color: ${Colors.gray};

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ProductDescriptionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-start: 5;
  grid-column-end: span 5;
`;

const ProductTitle = styled.h1`
  margin: 0;
  margin-top: 15px;

  font-size: 20px;
  line-height: 24px;
  font-weight: 500;

  opacity: ${props => (props.missing ? 0.5 : 1)};
`;

const ProductDescriptionRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: space-between;
  align-items: ${props =>
    props.align || props.flexDirection === 'column' ? 'flex-start' : 'center'};
  width: 100%;
  padding: 30px 0;

  ${props =>
    props.separator &&
    css`
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${Colors.lightGrey};
      }
    `};

  header {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  &.no-padding {
    padding: 0;
  }

  &.configurator {
    padding-top: 0;

    .external-configurator {
      flex-grow: 1;
      margin-left: 40px;
    }
  }

  .add-to-cart--container {
    position: relative;
    flex-grow: 1;
    margin-left: 40px;

    button {
      width: 100%;
    }

    .is-in-cart {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: calc(100% - 14px);
      left: calc(100% - 14px);
      padding: 6px;

      color: ${Colors.darkGreen};
      background-color: ${Colors.lightGreen};
      border-radius: 50%;

      pointer-events: none;
    }
  }
`;

const ProductDescriptionList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px 10px;
  font-size: 14px;
  line-height: 17px;

  dt,
  dd {
    display: block;
    margin-bottom: 10px;
  }

  dt::after {
    content: ' :';
  }

  dd {
    margin-left: 0.5ch;
  }
`;

const ConfiguratorButton = styled(Button)`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px !important;

  text-decoration: none;

  color: ${Colors.black} !important;
  background-color: white !important;
  box-shadow: 0 0 0 3px ${Colors.accent} !important;
  border-radius: 3px !important;

  transition-property: color, background-color, box-shadow !important;

  &:hover,
  &:focus {
    color: ${Colors.white} !important;
    background-color: ${Colors.accent} !important;
  }

  span {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
`;

const AddToCartButton = styled(Button)`
  &:disabled {
    opacity: 1 !important;
    background-color: ${Colors.lightGreen};
    color: ${Colors.darkGreen};
  }
`;

const RentalLabel = styled.span`
  display: inline-block;
  position: relative;
  padding: 5px 8px;

  color: ${Colors.white};
  background-color: ${Colors.primary};
  border-radius: 20px;
  font-size: 14px;
  line-height: 1;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);

  pointer-events: none;
  z-index: 100;
`;

const ResourceImageLoader = (
  <BlockPlaceholder
    height="100%"
    style={{
      position: 'absolute',
      top: '0',
      left: '0',
    }}
  />
);

const SingleProduct = ({
  match,
  products,
  familiesState,
  cartState,
  fetchSingleProduct,
  fetchSingleProductPdf,
  addToCart,
}) => {
  const history = useHistory();

  const [productCount, setProductCount] = useState(1);
  const [addedToCart, setAddedToCart] = useState(false);

  const { productID } = match.params;

  const isInCart = Object.keys(cartState?.present?.products).includes(
    productID,
  );

  const product = (products || {})[productID];

  useEffect(() => {
    fetchSingleProduct({
      sArticleCode: productID,
      sQuantity: productCount,
      skipLoading: true,
    });
  }, [productID, productCount]);

  useEffect(() => {
    fetchSingleProductPdf({ sArticleCode: productID });
  }, [productID]);

  useEffect(() => {
    return () => {
      fetchSingleProduct({
        sArticleCode: productID,
        clearSelf: true,
      });
    };
  }, []);

  useEffect(() => {
    if (addedToCart) {
      const id = setTimeout(() => {
        setAddedToCart(false);
      }, 1000);

      return () => clearTimeout(id);
    }
  }, [addedToCart]);

  usePageTitle((product || {}).sLibelle);

  if (!product || product.status === Status.LOADING) {
    return (
      <>
        <ScrollToTop />
        <ShopSidebar />
        <ProductContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner>Chargement...</Spinner>
          </div>
        </ProductContent>
      </>
    );
  }

  if (product.status === Status.ERROR) {
    history.push(Paths.NotFound());

    return null;
  }

  const family = product ? familiesState.families[product.sFamille] : null;
  const showConfig =
    (
      appConfig.get('settings.connections.mattresses.aisles', []) || []
    ).includes(family?.sRayon) ||
    (
      appConfig.get('settings.connections.mattresses.families', []) || []
    ).includes(product?.sFamille);

  const {
    sLibelle,
    sCommentaireAtelier,
    sManufacturerRef,
    sCodeLPP,
    sCodeEAN,
    sCodeGTIN,
    sBaseRemboursementTTC,
    sTauxTVA,
    iIsLoc,
    pdfStatus,
    pdfFiles,
  } = product;

  const isRental = Number.parseInt(iIsLoc, 10) === 1;

  const renderProductDescription = textContent => {
    if (textContent) {
      const formattedText = sanitize(textContent).replace(
        /[\r\n]{1,2}/g,
        '<br>',
      );

      return (
        <div
          style={{ margin: '1em 0' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: formattedText }}
        />
      );
    }

    return <div className="empty" />;
  };

  const renderProductDetails = () => {
    const lines = [
      { name: 'Référence catalogue', value: sManufacturerRef },
      { name: 'Code LPP', value: sCodeLPP },
      { name: 'Base de remboursement TTC', value: sBaseRemboursementTTC },
      { name: 'Code EAN', value: sCodeEAN },
      { name: 'Code GTIN', value: sCodeGTIN },
      { name: 'Taux TVA', value: sTauxTVA ? `${sTauxTVA}%` : null },
    ].filter(({ value }) => !!value);

    return (
      <ProductDescriptionList>
        {lines.map(({ name, value }) => (
          <React.Fragment key={name}>
            <dt>{name}</dt>
            <dd>{value}</dd>
          </React.Fragment>
        ))}
      </ProductDescriptionList>
    );
  };

  return (
    <>
      <ScrollToTop />
      <ErrorBoundary>
        <ShopSidebar />
        <ProductContent>
          <Button background="white" onClick={() => history.goBack()}>
            <ArrowLeftIcon />
            <span>Retour</span>
          </Button>
          <ProductRow>
            <ProductImageWrapper className="row-1">
              <ResourceImage code={productID} loader={ResourceImageLoader} />
            </ProductImageWrapper>

            <ProductDescriptionWrapper className="row-2">
              <ProductDescriptionRow>
                <ProductTitle missing={!sLibelle}>
                  {sLibelle || 'Libellé manquant'}
                </ProductTitle>

                {showConfig && (
                  <ExternalConfigurator
                    src={Configurators.PRESSURE_ULCER_PREVENTION}
                  >
                    <ConfiguratorButton
                      color={Colors.black}
                      background="white"
                      hoverBackground={Colors.accent}
                    >
                      <MattressIcon size={48} color="currentColor" />
                      <span>Configurateur</span>
                    </ConfiguratorButton>
                  </ExternalConfigurator>
                )}
              </ProductDescriptionRow>

              {isRental && (
                <ProductDescriptionRow className="no-padding">
                  <RentalLabel>Location</RentalLabel>
                </ProductDescriptionRow>
              )}

              <ProductDescriptionRow align="flex-start" separator>
                {appConfig.get('settings.useProductDelay') ? (
                  <ProductDelay product={product} quantity={productCount} />
                ) : (
                  <div className="delay dummy" />
                )}

                <ProductPrice
                  product={product}
                  mode={PriceMode.PRODUCT_SINGLE}
                  count={productCount}
                >
                  <span
                    className="small"
                    style={{
                      marginLeft: '10px',
                      fontSize: '8px',
                      lineHeight: '9px',
                      fontWeight: 300,
                      color: Colors.darkGrey,
                    }}
                  >
                    Hors taxe
                  </span>
                </ProductPrice>
              </ProductDescriptionRow>
              <ProductDescriptionRow flexDirection="column" separator>
                <Details summary="Description">
                  {renderProductDescription(sCommentaireAtelier)}
                </Details>
              </ProductDescriptionRow>
              <ProductDescriptionRow flexDirection="column" separator>
                <Details summary="Informations">
                  {renderProductDetails()}
                </Details>
              </ProductDescriptionRow>
              <ProductDescriptionRow>
                <NumberInput
                  value={1}
                  min={1}
                  style={{ width: '205px' }}
                  onChange={setProductCount}
                >
                  Quantité
                </NumberInput>

                <div className="add-to-cart--container">
                  <AddToCartButton
                    color={Colors.white}
                    background={Colors.primary}
                    style={{
                      fontSize: '15px',
                      lineHeight: '20px',
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      addToCart(product, productCount);
                      setAddedToCart(true);
                    }}
                    disabled={addedToCart}
                  >
                    {addedToCart ? 'Ajouté' : 'Ajouter au panier'}
                  </AddToCartButton>

                  {isInCart && !addedToCart && (
                    <div className="is-in-cart">
                      <CheckIcon size={14} />
                    </div>
                  )}
                </div>
              </ProductDescriptionRow>
            </ProductDescriptionWrapper>

            {pdfStatus === Status.LOADING ? (
              <div className="document-viewers loading col-4 row 1">
                <Spinner>Chargement des documents...</Spinner>
              </div>
            ) : (
              pdfFiles && (
                <div className="document-viewers col-4 row-1">
                  {pdfFiles.map(({ base64, title, lastUpdated }) => {
                    const pdfFormattedDate = lastUpdated
                      ? DateFormat.format(
                          lastUpdated,
                          Formats.DATETIME_HUMAN_READABLE,
                        )
                      : null;

                    return (
                      <DocumentViewer
                        key={`${kebabCase(title)}__${kebabCase(lastUpdated)}`}
                        base64={base64}
                        expand
                      >
                        <span className="title">{title}</span>

                        {pdfFormattedDate && (
                          <span className="date">
                            Document mis à jour le {pdfFormattedDate}
                          </span>
                        )}
                      </DocumentViewer>
                    );
                  })}
                </div>
              )
            )}
          </ProductRow>
        </ProductContent>
      </ErrorBoundary>
    </>
  );
};

SingleProduct.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,

  products: PropTypes.objectOf(PropTypes.any).isRequired,
  familiesState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchSingleProduct: PropTypes.func.isRequired,
  fetchSingleProductPdf: PropTypes.func.isRequired,
  cartState: PropTypes.shape({
    present: PropTypes.shape({
      products: PropTypes.objectOf(PropTypes.any),
    }),
  }).isRequired,

  addToCart: PropTypes.func.isRequired,
};

export const ConnectedSingleProduct = connect(
  state => ({
    ...state.products,
    familiesState: state.families,
    cartState: state.cart,
  }),
  { ...PRODUCT_ACTIONS, ...CART_ACTIONS },
)(SingleProduct);

export default {
  path: Paths.SingleProduct(),
  name: 'Produit',
  component: ConnectedSingleProduct,
  mapStateToProps: state => ({ ...state.products }),
  getFetchParams: ({ match }) => ({
    sArticleCode: match.params.productID,
  }),
  mapDispatchToProps: {
    fetch: PRODUCT_ACTIONS.fetchSingleProduct,
  },
  getDataFromState: ({ products, path, match }) => {
    const { params, url } = match;
    const { productID } = params;

    const product = (products || {})[productID];

    if (product) return { name: product.sLibelle, path: url };

    return { name: 'Produit', path };
  },
};
