import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import PlusIcon from '../../../assets/img/PlusIcon';
import { Colors, Sizing } from '../../styles/vars';

const TitleComponent = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column: 1 / span 12;
  height: 36px;
  margin: ${props => props.margin};

  span.children {
    margin-left: 15px;
    font-size: ${props => props.fontSize};
    line-height: ${props => props.lineHeight};
    font-weight: 500;
    color: ${Colors.accent};

    @media all and (max-width: ${Sizing.mobileWidth}) {
      font-size: ${props => `calc(${props.fontSize} * 0.75)`};
    }

    .sub {
      position: absolute;
      font-size: 0.6em;

      &.dim {
        opacity: 0.6;
      }
    }
  }

  @media print {
    margin: 8px 0 !important;

    svg {
      display: none;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-75%, -55%);

      pointer-events: none;
    }

    span.children {
      margin-left: 0;
      font-size: ${props => props.fontSize};
      line-height: ${props => props.lineHeight};
      font-weight: 500;
      color: ${Colors.accent};

      @media all and (max-width: ${Sizing.mobileWidth}) {
        font-size: ${props => `calc(${props.fontSize} * 0.75)`};
      }

      .sub {
        position: absolute;
        font-size: 0.6em;

        &.dim {
          opacity: 0.6;
        }
      }
    }
  }
`;

const PageTitle = ({
  fromComponent,
  margin,
  fontSize,
  lineHeight,
  style,
  additionalContent,
  className,
  children,
}) => (
  <TitleComponent
    as={fromComponent}
    margin={margin}
    fontSize={fontSize}
    lineHeight={lineHeight}
    style={style}
    className={className}
  >
    <PlusIcon size={36} />
    <span className="children">{children}</span>

    {additionalContent}
  </TitleComponent>
);

PageTitle.propTypes = {
  fromComponent: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  additionalContent: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PageTitle.defaultProps = {
  fromComponent: 'h1',
  margin: '45px 0',
  fontSize: '30px',
  lineHeight: '36px',
  style: {},
  className: null,
  additionalContent: null,
};

export default PageTitle;
